import { Injectable } from '@angular/core';
import { assign, curry, isNil } from 'lodash';

import { StateRecord } from '@mode/shared/contract-common';
import { TimerUtility, Timer } from './timer-utility';

enum VizTypes {
  Table = 'table',
  Chart = 'chart',
  Python = 'python',
  Text = 'text',
}

// Needed for passing in placeholder datasets to reducers.
interface Dataset extends StateRecord {
  content?: any[];
  columns?: any[];
  csv?: string;
  content_length?: number;
  bytes?: number;
  count?: number;
}

export const CALC_EDITOR_ERROR = 'calc_editor_error';
export const EVENT_PRIVATE_EDITOR_RUN = 'triggered_private_report_from_editor';
export const EVENT_TIMING_DESIGNER_VIZ_RENDER = 'timing_designer_viz_render';
export const EVENT_TIMING_REPORT_VIZ_RENDER = 'timing_report_viz_render';
export const EVENT_TIMING_TABLE_COLUMN_SELECT = 'timing_table_column_select';
export const EVENT_TIMING_TABLE_SORT = 'timing_table_sort';
export const TRIAL_PAGE_OPENED = 'trial_page_opened';
export const UPSELL_INTERACTION = 'upsell_interaction';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AnalyticsEvent {
  export enum Name {
    CalcEditorOpen = 'calc_editor_open',
    CalcFieldCreatedWhileExploring = 'calc_field_created_while_exploring',
    ClickChartDataSeries = 'click_chart_data_series',
    ClickDrilldownInterval = 'click_drilldown_interval',
    ClickedEditorVizExploreLink = 'clicked_editor_viz_explore_link',
    ClickExploreFromHere = 'click_explore_from_here',
    ClickViewData = 'click_view_data',
    ConnectDbIntentSignaled = 'connect_db_intent_signaled',
    CopyIntSharingRecentClicked = 'Copy - Internal Sharing - Most Recent Run',
    CopyIntSharingStaticClicked = 'Copy - Internal Sharing - Static Run',
    CopyExtSharingRecentClicked = 'Copy - External Sharing - Most Recent Run',
    CopyExtSharingStaticClicked = 'Copy - External Sharing - Static Run',
    DragStart = 'Drag start',
    DragEnd = 'Drag end',
    ExploreClickExplore = 'explore_click_explore',
    ExploreDecreaseGranularity = 'explore_decrease_granularity',
    ExploreIncreaseGranularity = 'explore_increase_granularity',
    ExploreSaveSuccess = 'explore_save_success',
    ExploreClickKebab = 'explore_click_kebab',
    ExternalSharingToggleOn = 'Toggle External Sharing On',
    ExternalSharingToggleOff = 'Toggle External Sharing Off',
    GetShareLinkClicked = 'get_share_link_clicked',
    GoogleSheetSetup = 'gsheet-setup-started',
    GoogleAuthSetup = 'gsheet-oauth-started',
    GoogleSheetUploadLaunched = 'gsheet-upload-launched',
    LoadingCollections = 'loading_collections',
    LoadingReport = 'loading_report',
    LoadingEditor = 'loading_editor',
    LoadingNotebook = 'loading_notebook',
    NewDataset = 'New Dataset',
    NewQuery = 'New Query',
    NewReport = 'New Report',
    NotebookSidebarContentImpression = 'notebook_sidebar_content_impression',
    NotebookSidebarExpanded = 'notebook_sidebar_expanded',
    NotebookSidebarCollapsed = 'notebook_sidebar_collapsed',
    NotebookSidebarTabChange = 'notebook_sidebar_tab_change',
    NotebookSidebarResourcesLinkClicked = 'notebook_sidebar_resources_link_clicked',
    NotebookSidebarExpandableTextToggled = 'notebook_sidebar_expandable_text_opened',
    NotebookEnvironmentSelectorClicked = 'notebook_environment_selector_clicked',
    ModalShown = 'Modal Shown',
    ManualSortSelected = 'Manual Sort Selected',
    ManualSortDomainRequested = 'Manual Sort Domain Requested',
    ManualSortDomainLoaded = 'Manual Sort Domain Loaded',
    MenuItemSelected = 'Menu Item Selected',
    MembersListPageLoad = 'members_list_page_load',
    PlanSelectionMade = 'plan_selection_made',
    ReorderDataset = 'reorder_datasets',
    ReportListFilterClicked = 'report_list_filter_clicked',
    ReportListSortClicked = 'report_list_sort_clicked',
    Search = 'Search',
    SkippedDBConnectionSignUpStep = 'user_skipped_db_connection_sign_up_step',
    TablePreviewClicked = 'table_preview_clicked',
    UiClicked = 'UI Clicked',
    UseExistingData = 'Use Existing Data',
    UserExperienceStarted = 'User Experience - User Experience Started',
    UserExperienceMilestone = 'User Experience Milestone',
  }
  export enum Context {
    Report = 'report',
    Notebook = 'notebook',
    Visualization = 'visualization',
    Dataset = 'dataset',
    Home = 'Home',
    Definitions = 'Definitions',
    Collections = 'Collections',
    Explorations = 'Explorations',
  }
  export enum SubContext {
    Chart = 'chart',
    ChartExpandedView = 'chart_expanded_view',
    DatasetBrowser = 'datasets browser',
    DatasetEditor = 'Dataset Editor',
    DatasetView = 'Dataset View',
    DefinitionEditor = 'Definition Editor',
    EditColorsModal = 'Edit Colors Modal',
    EditorView = 'Editor View',
    GoogleSheetSetup = 'setup-started',
    GoogleAuthSetup = 'oauth-started',
    GoogleSheetUploadAuthFlow = 'Auth flow',
    GoogleSheetUploadPlusMenu = 'Plus menu',
    GoogleSheetUploadTile = 'Shortcut tile',
    GoogleSheetUploadLaunched = 'upload-launched',
    ReportEditorSidebar = 'Report Editor - Sidebar',
    MetricBrowser = 'metric browser',
    MyWorkPage = 'My Work Page',
    MyWorkShortCuts = 'My Work - Shortcuts',
    NotebookView = 'Notebook',
    PresentationView = 'Presentation View',
    ReorderDataset = 'Reorder Datasets',
    ReportEditor = 'Report Editor',
    ReportEmptyState = 'Report Empty State',
    ReportView = 'Report View',
    SchemaBrowser = 'Schema Browser',
    SelectMetricViz = 'select metric viz',
    ShareReport = 'Share Report',
    Sidebar = 'sidebar',
    QueryNav = 'Query Nav',
    VizToolBar = 'Viz Toolbar',
    Exploration = 'Exploration',
    DownloadDrawer = 'Download Drawer',
    ExplorationsNav = 'ExplorationsNav',
    VizTab = 'VizTab',
    QueryTab = 'QueryTab',
  }

  export enum Interaction {
    Filter = 'filter',
    LoadingIndicator = 'loading indicator',
    NotebookOutput = 'notebook output',
    Visualization = 'visualization',
    VirtualizedReportRendered = 'virtualized report rendered',
    ExportCsv = 'export chart data to csv',
    DownloadCsv = 'download csv',
  }
}

export enum RunMethodType {
  Track = 'track',
  Identify = 'identify',
  TrackLink = 'trackLink',
}

export interface TrackClickData {
  location: string;
  action?: string;
  context?: string;
  contextId?: string;
  modalType?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  static EVENT_PRIVATE_EDITOR_RUN = EVENT_PRIVATE_EDITOR_RUN;
  static EVENT_TIMING_DESIGNER_VIZ_RENDER = EVENT_TIMING_DESIGNER_VIZ_RENDER;
  static EVENT_TIMING_REPORT_VIZ_RENDER = EVENT_TIMING_REPORT_VIZ_RENDER;
  static EVENT_TIMING_TABLE_COLUMN_SELECT = EVENT_TIMING_TABLE_COLUMN_SELECT;
  static EVENT_TIMING_TABLE_SORT = EVENT_TIMING_TABLE_SORT;

  private _context!: AnalyticsEvent.Context;
  public get context(): AnalyticsEvent.Context {
    return this._context;
  }

  private _timerUtility = new TimerUtility();
  private _performance = window.performance;
  private _timers: { [eventTimer in AnalyticsEvent.Name]: (num: number) => {} } = {} as any;

  public userExperienceId = (window as { [k: string]: any })['userExperienceId'] || '';

  static buildKey(namespace: string, id: string): string {
    return [namespace, id].join('::');
  }

  constructor(private _window: Window) {}

  /**
   * Dynamically get Segment's analytics.js client from Window.  Analytics.js
   * is hot reloaded at runtime by segment so it's necessary to retrieve it's
   * reference dynamically
   * @private
   */
  private get _segment() {
    return (this._window as { [k: string]: any })['analytics'] || {};
  }

  /**
   * Timing
   *
   * Tracking elapsed time that require a start time
   * and end time which are usually required at different points during
   * runtime
   * @example
   *  let timingStart = AnalyticsService.timing('my_event', { user: 1234});
   *  let timingEnd = timingStart(window.performance.now())
   *  ...
   *  ...
   *  timingEnd(window.performance.now())
   * @param event
   * @param props
   * @param opts
   * @param cb
   * @returns {CurriedFunction1<T1, R>|CurriedFunction2<T1, T2, undefined>|TResult}
   */
  timing(event: string, props?: any, opts?: { [k: string]: any }, cb?: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    const run = context.run;

    return curry(function trackTiming(startTime: number, endTime: number) {
      const trackProps = assign({}, props, { timingValue: endTime - startTime });
      const trackArgs = [event, trackProps, opts, cb].filter((v) => !isNil(v));

      // [KEEP] very useful when developing/debugging
      // console.log(event, trackProps);
      run.apply(context, ['track', ...trackArgs]);
    });
  }

  /**
   * Call identified Segment "analytics.js" API method with provided args. Refrain
   * from referencing {_segment} directly
   * @example
   *  AnalyticsService.run('track', 'my_event', {foo: 'bar'});
   *  // -> analytics.track('my_event', {foo: 'bar'})
   *  AnalyticsService.run('identify', '12345', {name: 'buddy'});
   *  // -> analytics.identify('12345', {name: 'buddy'})
   * @see https://segment.com/docs/sources/website/analytics.js/ - api docs
   * @param method
   * @param args
   */
  run(method: string, ...args: any) {
    const api = this._segment;

    if (typeof api[method] === 'function') {
      // eslint-disable-next-line prefer-spread
      api[method].apply(api, args);
    }
  }

  track(event: AnalyticsEvent.Name, ...args: any) {
    this.run(RunMethodType.Track, event, ...args);
  }

  /**
   * Get curried timing function with standard viz timing properties
   * @example
   *  let startTime = 100;
   *  let endTime = 130;
   *  let trackTiming = AnalyticsService.vizTimingHelper('event_name', 'myChart', {'report': 'foo'})
   *  trackTiming(startTime, endTime);
   *  // -> is equivalent to...
   *  // -> analytics.track('event_name', {
   *  // ->   vizType: 'myChart',
   *  // ->   timingValue: 30,
   *  // ->   report: 'foo'
   *  // -> })
   * @param eventName
   * @param type
   * @param additionalProps
   * @returns {CurriedFunction1<number, R>|CurriedFunction2<number, number, undefined>|TResult}
   */
  vizTimingHelper(eventName: string, type: VizTypes, additionalProps?: { [k: string]: any }) {
    return this.timingHelper(eventName, assign({}, additionalProps, { vizType: type }));
  }

  /**
   * Get curried timing function
   * @example
   *  let startTime = 100;
   *  let endTime = 130;
   *  let trackTiming = AnalyticsService.timingHelper('event_name', {'name': 'bar'})
   *  trackTiming(startTime, endTime);
   *  // -> is equivalent to...
   *  // -> analytics.track('event_name', {
   *  // ->   timingValue: 30,
   *  // ->   name: 'bar'
   *  // -> })
   * @param eventName
   * @param additionalProps
   * @returns {CurriedFunction1<number, R>|CurriedFunction2<number, number, undefined>|TResult}
   */
  timingHelper(eventName: string, additionalProps?: { [k: string]: any }) {
    return this.timing(eventName, assign({}, additionalProps));
  }

  /**
   * @param vizID - visualization token
   */
  startVizRenderDesignerTiming(vizID: string) {
    this._startTimer(EVENT_TIMING_DESIGNER_VIZ_RENDER, vizID);
  }

  /**
   * @param vizID - visualization token
   */
  startVizRenderReportTiming(vizID: string) {
    this._startTimer(EVENT_TIMING_REPORT_VIZ_RENDER, vizID);
  }

  /**
   * @param tableID - visualization token
   */
  startColumnSelectTiming(tableID: string) {
    this._startTimer(EVENT_TIMING_TABLE_COLUMN_SELECT, tableID);
  }

  /**
   * @param tableID - visualization token
   */
  startTableSortTiming(tableID: string) {
    this._startTimer(EVENT_TIMING_TABLE_SORT, tableID);
  }

  /**
   * @param vizID - visualization token
   */
  endVizRenderDesignerTiming(vizID: string, vizType: VizTypes) {
    this._endAndBroadcastTiming(EVENT_TIMING_DESIGNER_VIZ_RENDER, vizID, vizType);
  }

  /**
   * @param vizID - visualization token
   */
  endVizRenderReportTiming(vizID: string, vizType: VizTypes) {
    this._endAndBroadcastTiming(EVENT_TIMING_REPORT_VIZ_RENDER, vizID, vizType);
  }

  /**
   * @param tableID - visualization token
   * @param queryToken
   */
  endColumnSelectTiming(tableID: string, dataset: Dataset) {
    this._endAndBroadcastTableTiming(EVENT_TIMING_TABLE_COLUMN_SELECT, tableID, dataset);
  }

  /**
   * @param tableID - visualization token
   * @param queryToken
   */
  endTableSortTiming(tableID: string, dataset: Dataset) {
    this._endAndBroadcastTableTiming(EVENT_TIMING_TABLE_SORT, tableID, dataset);
  }

  recordPerformanceEvent(eventName: string, params: any, pageLoaded = false) {
    if (typeof this._performance.mark === 'function') {
      this._performance?.mark(eventName);
    }
    if (typeof this._performance.measure === 'function') {
      this._performance?.measure(eventName);
    }
  }

  startTiming(event: AnalyticsEvent.Name, properties: {}): void {
    const timingStart = this.timing(event, properties);
    const timer = timingStart(window.performance.now());
    this._timers[event] = timer;
  }

  endTiming(event: AnalyticsEvent.Name): void {
    const timer = this._timers[event] ?? (() => {});
    timer(window.performance.now());
  }

  public setContext(context: AnalyticsEvent.Context): void {
    this._context = context;
  }

  private _startTimer(keyNamespace: string, keyID: string) {
    this._timerUtility.start(TimerUtility.buildKey(keyNamespace, keyID));
  }

  private _endAndBroadcastTableTiming(eventName: string, tableID: string, dataset: Dataset) {
    const timerKey = TimerUtility.buildKey(eventName, tableID);
    let timer;

    if (this._timerUtility.hasTimer(timerKey)) {
      timer = this._endTimer(eventName, tableID);
      if (timer) {
        this._broadcastTiming(timer, eventName, {
          rowCount: dataset.count,
          columnCount: dataset.columns?.length,
        });
      }
    }
  }

  private _endAndBroadcastTiming(eventName: string, vizID: string, vizType: VizTypes) {
    const timerKey = TimerUtility.buildKey(eventName, vizID);

    if (this._timerUtility.hasTimer(timerKey)) {
      const timer = this._endTimer(eventName, vizID);
      if (timer) {
        this._broadcastVizTiming(timer, eventName, vizType);
      }
    }
  }

  private _endTimer(keyNamespace: string, keyID: string): Timer | null {
    const timingKey = TimerUtility.buildKey(keyNamespace, keyID);
    return this._timerUtility.end(timingKey);
  }

  private _broadcastTiming(timer: Timer, eventName: string, additionalProps = {}) {
    const { startTime, endTime } = timer;
    const timingTracker = this.timingHelper(eventName, additionalProps);

    if (startTime && endTime) {
      timingTracker(startTime, endTime);
    }
  }

  private _broadcastVizTiming(timer: Timer, eventName: string, vizType: VizTypes) {
    const { startTime, endTime } = timer;
    const vizTimingTracker = this.vizTimingHelper(eventName, vizType);

    if (startTime && endTime) {
      vizTimingTracker(startTime, endTime);
    }
  }
}
