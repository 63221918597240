import { Observable } from 'rxjs';

export const enum FeatureFlag {
  GroupsVisibility = 'groups-visibility',
  IFrameBlockSameOrigin = 'iframe-sandbox',
  IFrameFSNoReload = 'iframe-fs-no-reload',
  IFrameAllowForms = 'iframe-allow-forms',
  IFrameAllowPopupEscape = 'iframe-allow-popup-escape',
  DuplicateQueryAndViz = 'duplicate-query-and-viz',
  UseTronLegacyCharts = 'use-tron-legacy-charts',
  DisableFlamingoDisplayTable = 'disable-flamingo-display-table',
  HideSelectAllFilters = 'filters-hide-select-all',
  VirtualizedReportDisableDomCache = 'virtualized-report-disable-dom-cache',
  AlgoliaKeyDuration = 'algolia-key-duration',
  SkipReportAccountResolver = 'skip-report-account-resolver',
  DetailedPerformance = 'detailed-performance',
  LimitReportPropertyList = 'limit-report-property-list',
  AzureActiveDirectoryScim = 'azure-active-directory-scim',
  MyCollectionsSearch = 'my-collections-search',
  FastReportView = 'fast-report-view',
  CreateNewReportPerformance = 'create-new-report-performance',
  RouteTrafficToEcs = 'route-traffic-to-ecs-flamingo',
  AngularMigration = 'angular-15-migration',
  CsvExportRowLimit = 'csv-export-row-limit',
  FlamingoRequestLimit = 'perm-webapp-DASH-172-flamingo-request-limit',
  NoQuillViewer = 'temp-webapp-DASH-216-simple-text-renderer',
  EnableCustomReportVirtualization = 'enable-custom-report-virtualization',
  CollectionCreationRestriction = 'temp-webapp-2023.04-jira-UCM-226-collection-creation-restriction',
  NewReportViewerTracing = 'temp-new-report-viewer-tracing',
  UnaggregatedChartExport = 'unaggregated-chart-export',
  CustomPageSizeEnabled = 'adjust-flat-tables-page-size',
  AutoRedirectToProvidedSSO = 'temp-webapp-2013.11-jira-PLG-414-auto-redirect-to-sso-provided-by-query-params',
  HistogramQuickChart = 'histogram-quick-chart',
  CustomCalendar = 'custom-calendar',

  // Reports & Datasets
  CustomJsDatasetSupport = 'temp-webapp-04.2023-jira-RAD-555-custom-js-dataset-support',
  AlamodeErrorReporting = 'perm-webapp-05.2023-jira-RAD-791-enable-alamode-error-reports',
  ShowAngularFlamingoGrid = 'temp-webapp.2023.06-jira-789-show-angular-flamingo-grid',
  ReportRunToast = 'temp-webapp-2023.07-github-rad-report-view-run-toast',
  RemoveDatasetSideEffects = 'temp-webapp-2023.07-jira-rad-796',
  ReportFilterDropdownEnabled = 'report-filter-dropdowns',
  ReportFiltersMigration = 'report-filters-migration',
  ReportFiltersDragAndDrop = 'report-filters-drag-and-drop',
  ReportFiltersDisplayRelevantValues = 'report-filters.display-relevant-values-enabled',
  SchedulerMigrationPhase = 'temp-webapp-2023.12-jira-RAD-938-phased-scheduler-migration',

  // Report Capture
  RenderingReporterEventDelay = 'perm-webapp-2023.02-github-17723-report-rendered-event-delay',
  LongCappieWorkEvent = 'perm-webapp-2023.05-jira-da559-long-cappie-work-event',
  ShowReportParametersForCapture = 'perm-webapp-2023.04-github-18712-optional-report-parameters-during-capture',

  // User & Content Management
  LoggedOutSignInFlow = 'logged-out-sign-in-flow',
  LoggedOutAnalytics = 'logged-out-analytics',
  LoggedOutTracking = 'logged-out-tracking',
  EnableDiscoverPageForStudioPlan = 'temp-webapp-2023-plg-enable-discover-page-for-studio-plan',
  BulkScheduleManagement = 'temp-webapp-github-plg-266-bulk-schedule-management',
  BulkScheduleManagementFilterAndSort = 'bulk-schedule-management-filter-and-sort',
  AngularSignupRevamp = 'temp-webapp-2023-jira-plg-403-signup-migration',
  WorkspaceMemberApiKeys = 'workspace-member-api-keys',

  // VizExplorer
  VizExplorerMaxElementCount = 'viz-explorer-max-element-count',
  VizExplorerMaxFacetsPerPage = 'viz-explorer-max-facets-per-page',
  DatasetFieldSettingsFormattingColumnEnabled = 'dataset-field-settings-formatting-column-enabled',
  VizExplorerSubtotals = 'temp-webapp-2024.05-jira-MAC-1531-subtotals',

  // Query Editor
  AIAssistEnabled = 'temp-webapp-2023.06-ai-features',
  ReorderingDatasets = 'temp-webapp-2024.07-github-NIDE-223-report-views-ranking',

  // Data Acquisition
  GoogleSheetsUpload = 'temp-2023-07-jira-daq-605',

  // Customer Acquisition
  HideFreePlanSelection = 'temp-510-hide-free-plan-selection-for-signup-flow',

  // Notebooks
  RegisterImportedDatasetsNotebooks = 'temp-webapp-2024.04-jira-NIDE-53-register-imported-datasets-notebooks',
  RegisterSecureCredentialsNotebooks = 'temp-webapp-2024.07-jira-NIDE-94-register-secure-credential-notebooks',
  ShowArchivedBadge = 'temp-webapp-NIDE-138-add-archived-badge',
  ShowDatasetsPagination = 'nide-173-enable-pagination-for-datasets-serach',
  ShowNamedSchedules = 'temp-webapp-NIDE-166-named-schedules',
  ShowCteAutocomplete = 'temp-webapp-2024.07-jira-NIDE-161-add-ctes-in-auto-complete',
  NotebookExportDatasets = 'temp-webapp-2024.07-jira-NIDE-96-notebook-cell-local-datasets',

  // MAC - Mode Application Core
  NewReportLyftBugDelay = 'temp-webapp-2024.05-jira-MAC-1455-new-report-lyft-bug-delay',
  NewReportLyftBug = 'temp-webapp-2024.05-jira-MAC-1455-new-report-lyft-bug',

  // Rebrand
  RebrandMilestone2 = 'temp-rebrand-milestone-2-2024.07',
  RebrandMilestone3 = 'temp-rebrand-milestone-3-2024.07',
  RebrandMilestone4 = 'temp-rebrand-milestone-4-2024.07',
  NineDotAppSwitcher = 'temp-webapp-2024.10-MAC-1974-nine-dot-app-switcher',

  // Dataset Publication
  DatasetPublishing = 'temp-webapp-2024.08-DAQ-1392-dataset-publishing',
}

export type AllFlags = {
  readonly [K in FeatureFlag]: boolean;
};

export abstract class FeatureFlagsFacade {
  abstract allFlags$: Observable<AllFlags>;
  abstract asObservable(feature: FeatureFlag): Observable<boolean>;
  abstract featureValueAsObservable<Type extends string | number | boolean>(feature: FeatureFlag): Observable<Type>;
  abstract asPromise(feature: FeatureFlag): Promise<boolean | undefined>;

  abstract init(allFlags: AllFlags): void;
}

export abstract class FeatureFlagsInitializationService {
  abstract initFlags(): Promise<void>;
}
